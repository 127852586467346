import { loadTranslations } from '@angular/localize';
import { locale, loadMessages } from "devextreme/localization";
import { getSavedLocaleCode } from '../../../lib-shared/localization/src/SavedLocaleCode';
import { getSavedLanguageCode } from '../../../lib-shared/localization/src/SavedLocaleCode';

export function mpLoadTranslations(): Promise<boolean | void> {
    const localeCode = getSavedLocaleCode();
    if (localeCode && localeCode !== 'en') {
        return import('../../../lib-shared/localization/src/locale/messages.' + localeCode + '.json').then(mpLoc => {
            loadTranslations(mpLoc.default);
        }).catch(err => console.error(err));
    } else {
        return Promise.resolve(false);
    }
}

export function devExLoadTranslations() {
    const localeCode = getSavedLocaleCode();
    const languageCode = getSavedLanguageCode();
    if (languageCode && languageCode !== 'en') {
        import('../../../lib-shared/localization/src/locale/devextreme.' + languageCode + '.json').then(dxLoc => {
            loadMessages(dxLoc);
        });
    }
    if (localeCode && localeCode !== 'en') {
        locale(localeCode);
    }
}
